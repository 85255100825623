import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSkeletonLoader from 'skeleton-loader-vue';
import Skeleton from 'vue-loading-skeleton';
 
Vue.use(Skeleton)
Vue.use(BootstrapVue)
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

