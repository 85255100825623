export default {  
  setLoading(state, value){
    state.loading = value;
  },
  setVehicles(state, value){
    state.vehicles = value;
  },
  setDetails(state, value){
    state.detail = value;
  },
  setOtherVechiles(state, value){
    state.other_vehicles = value;
  },
  setFilters(state, value){
    state.filters = value;
  },
};