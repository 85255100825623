export default {
    methods: {
        groupBy(xs, key){
            let data = xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});              
              return data;
        }
    }
}