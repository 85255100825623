import axios from 'axios';

export default {	
	async getVehiclesList(params){
		return axios.get('/api/v1/dealers/vehicles-useds-list', {params: params});
	},
	async getDetailVehicle(params){
		return axios.get('/api/v1/dealers/vehicles-used-detail/'+params.slug);
	},
	async getFilterUseds(){
		return axios.get('/api/v1/dealers/vehicles-used-filters');
	}
}