import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    categories: [],
    detail: {},
    vehicles: [],
    other_vehicles: [],
    loading: false,
  },
  actions,
  getters,
  mutations,
};