<template>
    <div class="content-header-itens-links-div container">
        <router-link class="btn-links" :to="{name: 'home'}">
                {{ $t("header.link_home")|stripHtml }}
            </router-link>
        <template v-for="menu in layout.menu_header">
            <router-link v-if="menu.page != 'external'" :key="menu.name" class="btn-links" :to="{name: menu.page}">
                {{ menu.name }}
            </router-link>
            <a v-else :key="menu.name" class="btn-links" :href="menu.url">
                {{ menu.name }}
            </a>
        </template>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('Site', ['layout']),
    }
}
</script>