// i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { DefaultLang } from './locales/lang'
import Site from './api/Site';

Vue.use(VueI18n)

/**
 * variavel activeLocale vem de uma const que esta no layout.htm
 */
export const i18n = new VueI18n({
  locale: document.activeLocale,
  fallbackLocale: document.activeLocale
})

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

let transRoutes = DefaultLang[document.brandName].en;

if(document.activeLocale in DefaultLang[document.brandName]){
  transRoutes = DefaultLang[document.brandName][document.activeLocale];
}

if(sessionStorage.getItem('lang_json')){
  transRoutes = JSON.parse(sessionStorage.getItem('lang_json'));
}

export const langRoutes = {...transRoutes, locale: document.activeLocale};

/**
 * Load languages with base in language from head html
 * @param {*} lang 
 * @returns 
 */
export async function loadLanguageAsync (lang) {
  lang = lang ? lang.toLowerCase() : lang;  

  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

  if(sessionStorage.getItem('lang') == lang && sessionStorage.getItem('lang_json')){
    let msgs = JSON.parse(sessionStorage.getItem('lang_json'));
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs)    
    setI18nLanguage(lang)
    return Promise.resolve()
  }else{
    //Site.getBrandSite().then(async (responseBrand) => {
      //let json = responseBrand.data.data;
      // let brandName = Object.keys(json).length > 0 && json.company.brand ? json.company.brand.slug : null;       
      let brandName = document.brandName;
      
      let params = {
        lang : lang,
        contents : (brandName ? ((lang in DefaultLang[brandName]) ? DefaultLang[brandName][lang] : 'en') : {}),
      };

      sessionStorage.setItem('lang', lang);

      const response = await Site.getContentLang(params);
      
      let msgs = response.data;
      sessionStorage.setItem('lang_json', JSON.stringify(msgs));

      loadedLanguages.push(lang);
      i18n.setLocaleMessage(lang, msgs);
      setI18nLanguage(lang);
    //})    
  }
}