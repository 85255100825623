export default {  
  setLoading(state, value){
    state.loading = value;
  },
  setVehicles(state, value){
    state.vehicles = value;
  },
  setDetails(state, value){
    state.detail = value;
  },
  setCategories(state, value){
    state.categories = value;
  },
  setOtherVechiles(state, value){
    state.other_vehicles = value;
  },
};