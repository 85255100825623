import Vue from 'vue'
import VueRouter from 'vue-router'

import Blog from '../views/Blog.vue'
import BlogIntern from '../views/BlogIntern.vue'

import Page404 from '../views/404.vue'
import {loadLanguageAsync, langRoutes } from '@/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "vehicle-details" */'../views/Home'),
    alias: '/:locale'
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_new_details}/:slug?`,
    name: 'vehicleNewDetail',
    component: () => import(/* webpackChunkName: "vehicle-new-details" */'../views/VehicleNewDetails')
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_news}`,
    name: 'vehicleNews',
    component: () => import(/* webpackChunkName: "vehicle-news" */'../views/VehicleNews'),
  },
  {
    path: `/:locale/${langRoutes.routes.direct_sales}`,
    name: 'directSales',
    component: () => import(/* webpackChunkName: "direct-sales" */'../views/DirectSales'),
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_consortium}`,
    name: 'vehicleConsortium',
    component: () => import(/* webpackChunkName: "consortium" */'../views/Consortium'),
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_test_drive}`,
    name: 'vehicleTestDrive',
    component: () => import(/* webpackChunkName: "direct-sales" */'../views/TestDrive'),
  },
 {
    path: `/:locale/${langRoutes.routes.vehicle_parts}`,
    name: 'vehicleParts',
    component: () => import(/* webpackChunkName: "vehicle-parts" */'../views/VehicleParts'),
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_schedule}`,
    name: 'vehicleSchedule',
    component: () => import(/* webpackChunkName: "vehicle-schedule" */'../views/VehicleSchedule'),
  },
  {
    path: `/:locale/${langRoutes.routes.about}`,
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */'../views/About'),
  },
  {
    path: `/:locale/${langRoutes.routes.contact}`,
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */'../views/Contact'),
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_useds}`,
    name: 'vehicleUseds',
    component: () => import(/* webpackChunkName: "contact" */'../views/VehicleUseds'),
  },
  {
    path: `/:locale/${langRoutes.routes.vehicle_useds}/:slug?`,
    name: 'vehicleUsedDetail',
    component: () => import(/* webpackChunkName: "vehicle-used-details" */'../views/VehicleUsedDetails')
  },
  {
    path: `/:locale/${langRoutes.routes.policy_privacy}`,
    name: 'policyPrivacy',
    component: () => import(/* webpackChunkName: "vehicle-used-details" */'../views/PoliticsPrivacy')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog-intern',
    name: 'BlogIntern',
    component: BlogIntern
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  },
  {
    path: '*',
    redirect: '/404'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {  
  to.params.locale = document.activeLocale;
  loadLanguageAsync(document.querySelector('html').getAttribute('lang')).then(() => next())
})

router.beforeEach((to, from, next) => {  
  setTimeout(() => {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://platform.4tech.mobi/assets/scripts/js/TechMobi.js')
    document.head.appendChild(recaptchaScript)
  },300)
  next();
})

export default router
