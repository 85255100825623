import Site from "@/api/Site";

export default {    
    bind: function (el, binding) {            
        let params = binding.value ? binding.value : {};            
    
        Site.getContent(params).then(response => {
            let data = response.data.data;
            
            if(params.type == 'text'){
                el.innerHTML = data;
            }            
        }) 
    }
  };

  