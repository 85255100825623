export default {  
  setMenuOpen(state, value){
    state.menuOpen = value;
  },
  setActiveMenuFooter(state, value){
    state.activeMenuFooter = value;
  },
  setActiveFormFloating(state, value){
    state.activeFormFloating = value;
  },
  setIsDesktop(state, value){
    state.isDesktop = value;
  },
  setLoading(state, value){
    state.loading  = value;
  },
  setLoadingBanner(state, value){
    state.loadingBanner  = value;
  },
  setLoadingBlog(state, value){
    state.loadingBlog = value;
  },
  setBlogs(state, value){
    state.blogs = value;
  },
  setBlogDetail(state, value){
    state.blogDetail = value;
  },
  setBanners(state, value){
    state.banners = value;
  },
  setLayout(state, value){
    // temporary
    // sessionStorage.setItem('layout_configs', JSON.stringify(value));
    state.layout = value;
  }
};