import VehicleUsed from '@/api/VehicleUsed';

export default {   
  async loadVehicleUseds(context, params){  
    context.commit('setLoading', true);  
    await VehicleUsed.getVehiclesList(params).then((json) => {
      let response = json.data;
      context.commit('setLoading', false);      
      context.commit('setVehicles', response.data.vehicles);
    });
  },
  async loadVehicleDetail(context, params){  
    context.commit('setLoading', true);  
    await VehicleUsed.getDetailVehicle(params).then((json) => {
      let response = json.data;
      context.commit('setLoading', false);
      context.commit('setDetails', response.data.vehicle);      
      context.commit('setOtherVechiles', response.data.other_vehicles);
    });
  },
  async loadFilters(context, params){    
    await VehicleUsed.getFilterUseds(params).then((json) => {
      let response = json.data;          
      context.commit('setFilters', response.data);
    });
  },
};