<template>
	<div class="container d-none d-lg-flex justify-content-end">
		<div class="content-form-cotation form-fixed" :class="{'form-fixed-active': activeFormFloating}">
			<div class="content-form-cotation-header">
				<h2>SOLICITE AQUI O SEU ORÇAMENTO</h2>
			</div>
			<div class="content-form-cotation-menu">
				<a class="content-links" @click="tabMenuActive = 'new'" :class="{
                'active-tab': tabMenuActive && tabMenuActive == 'new'
                }">
                <i v-html="iconVehicle"></i>
                <p>{{ $t('form.tab_news')|stripHtml }}</p>
            </a>
            <a class="content-links" @click="tabMenuActive = 'new-ones'" 
                :class="{'active-tab': tabMenuActive && tabMenuActive == 'new-ones'}">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.144"
                height="26.144"
                viewBox="0 0 26.144 26.144"
                >
                <g transform="translate(0 0)">
                    <path
                    d="M175.814,124.972l3.611-10.832a.766.766,0,1,0-1.453-.484l-3.611,10.832a.766.766,0,1,0,1.453.484Z"
                    transform="translate(-165.42 -107.354)"

                    />
                    <path
                    d="M266.831,193.8a2.3,2.3,0,1,0-2.3-2.3A2.3,2.3,0,0,0,266.831,193.8Zm0-3.064a.766.766,0,1,1-.766.766A.767.767,0,0,1,266.831,190.735Z"
                    transform="translate(-251.025 -179.541)"

                    />
                    <path
                    d="M97.125,189.2a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,97.125,189.2Zm0,3.064a.766.766,0,1,1,.766-.766A.767.767,0,0,1,97.125,192.267Z"
                    transform="translate(-89.985 -179.541)"

                    />
                    <path
                    d="M25.991,9.5,23.32,5.937l.1-.729a.766.766,0,0,0-.217-.65L21.583,2.933l1.625-1.625A.766.766,0,1,0,22.125.225L20.5,1.849,18.875.225a.767.767,0,0,0-.65-.217L10.643,1.091a.766.766,0,0,0-.433.217L.821,10.7a2.812,2.812,0,0,0,0,3.972l7.944,7.944a2.8,2.8,0,0,0,.529.415v.311A2.812,2.812,0,0,0,12.1,26.145H23.336a2.812,2.812,0,0,0,2.808-2.808V9.958a.766.766,0,0,0-.153-.46ZM1.9,13.584a1.278,1.278,0,0,1,0-1.805l9.207-9.207,6.951-.993,1.354,1.354L18.333,4.016l-.542-.542a.766.766,0,1,0-1.083,1.083l2.166,2.166a.766.766,0,0,0,1.083-1.083L19.417,5.1,20.5,4.016,21.854,5.37l-.993,6.951-9.207,9.207a1.277,1.277,0,0,1-1.805,0Zm22.708,9.752a1.278,1.278,0,0,1-1.277,1.277H12.1a1.278,1.278,0,0,1-1.273-1.182,2.8,2.8,0,0,0,1.907-.819l9.388-9.388a.766.766,0,0,0,.217-.433l.673-4.709,1.6,2.132Z"
                    transform="translate(0 0)"

                    />
                </g>
                </svg>
                <p>{{ $t('form.tab_useds')|stripHtml }}</p>
            </a>
            <a class="content-links" @click="tabMenuActive = 'revision'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'revision'}">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.483"
                height="24.576"
                viewBox="0 0 24.483 24.576"
                >
                <g transform="translate(-0.903 -0.001)">
                    <path
                    d="M25.362,5.974a.565.565,0,0,0-.961-.351L21.224,8.8,17.7,7.685,16.587,4.163,19.764.987a.564.564,0,0,0-.351-.962A6.534,6.534,0,0,0,12.737,8.85c-.1.081-.191.166-.284.259L1.85,19.114l-.011.011a3.188,3.188,0,1,0,4.508,4.508l.015-.016L16.28,12.932a3.746,3.746,0,0,0,.253-.283,6.534,6.534,0,0,0,8.828-6.675ZM4.749,22.3a1.176,1.176,0,1,1,0-1.663A1.176,1.176,0,0,1,4.749,22.3Z"
                    transform="translate(0 0)"

                    />
                </g>
                </svg>
                <p>{{ $t('form.tab_revision')|stripHtml }}</p>
            </a>
            <a class="content-links" @click="tabMenuActive = 'help'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'help'}">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.144"
                height="26.144"
                viewBox="0 0 26.144 26.144"
                >
                <path
                    d="M17.351,10.911c1.209,0,1.936.645,1.936,1.733,0,1.976-3.528,2.521-3.528,4.92a1.282,1.282,0,0,0,1.289,1.35c1.331,0,1.17-.988,1.654-1.694.644-.947,3.649-1.955,3.649-4.576,0-2.842-2.539-4.032-5.12-4.032-2.458,0-4.718,1.754-4.718,3.73a1.206,1.206,0,0,0,1.311,1.23C15.779,13.573,14.772,10.911,17.351,10.911Z"
                    transform="translate(-4.36 -3.001)"
                />
                <path
                    d="M20.367,28.089a1.653,1.653,0,1,0-1.653,1.653A1.66,1.66,0,0,0,20.367,28.089Z"
                    transform="translate(-5.944 -9.211)"
                />
                <path
                    d="M26.144,13.072A13.072,13.072,0,1,0,13.072,26.144,13.087,13.087,0,0,0,26.144,13.072Zm-24.841,0A11.769,11.769,0,1,1,13.072,24.841,11.783,11.783,0,0,1,1.3,13.072Z"
                />
                </svg>
                <p>{{ $t('form.tab_questions')|stripHtml }}</p>
            </a>
			</div>
			<FormCotation :tabMenuActive="tabMenuActive"/>
		</div>
	</div>
</template>

<script>
import { THEME } from '@/LayoutTheme.js';
import FormCotation from "./Form";
import { mapGetters } from 'vuex';

export default {
	components: {
		FormCotation,
	},
	props: {
		activeFormFloating: Boolean
	},
	computed: {
        ...mapGetters('Site', ['layout']),
    },
    mounted(){        
        this.iconVehicle = THEME[this.layout.company.brand.slug].iconVehicle;
    },
    data(){
		return {
			tabMenuActive: 'new',  
            iconVehicle: '',          
		}
	},
}
</script>

<style lang="scss">
.form-fixed {
	position: fixed !important;
	top: 80px !important;
	bottom: 0;
	transform: translateY(-100%);
	height: max-content;
	transition: 500ms ease-in-out;
	z-index: 0 !important;
	@media (min-width: 992px) and (max-height: 900px){
		overflow-y: auto;
	}
	&-active {
		z-index: 2 !important;
		transform: translateY(0);
	}
}
</style>