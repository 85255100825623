import Site from '@/api/Site';

export default { 
  toggleFormFixed(context){
    if (context.getters.isDesktop){
      context.commit('setActiveFormFloating', !context.getters.activeFormFloating);
      if (context.getters.activeFormFloating){
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        document.getElementsByClassName('backdrop_bar')[0].classList.remove('d-none');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        document.getElementsByClassName('backdrop_bar')[0].classList.add('d-none');
      }
    }else {
      if (context.getters.activeMenuFooter){
        context.commit('setActiveMenuFooter', false);
      }else {
        context.commit('setActiveMenuFooter', true);
      }
    }
  },
  menuOpen(context, value){
    context.commit('setMenuOpen', value)
  },
  setActiveFormFloating(context, value){
    context.commit('setActiveFormFloating', value)
  },
  setIsDesktop(context, value){
    context.commit('setIsDesktop', value)
  },
  loading(context, value){
    context.commit('setLoading', value)
  },
  async loadLayoutConfigs(context){    
    await Site.getLayoutConfigs().then((json) => {
      let response = json.data;
      if(Object.keys(response.data).length == 0){
        document.location.href = '/not-found';
      }else{
        context.commit('setLayout', response.data)
      }
    }) 
  },
  async loadBanners(context){  
    context.commit('setLoadingBanner', true);  
    await Site.getBanners().then((json) => {
      let response = json.data;
      context.commit('setLoadingBanner', false);
      context.commit('setBanners', response.data);
    }) 
  },
  async loadBlogs(context, params){  
    context.commit('setLoadingBlog', true);  
    await Site.getBlogs(params).then((json) => {
      let response = json.data;
      context.commit('setLoadingBlog', false);
      if('slug' in params){
        context.commit('setBlogDetail', response.data);
      }else{
        context.commit('setBlogs', response.data);
      }      
    }) 
  }
};