<template>
  <!-- style="--main-color: initial" -->
  <div id="app" @scroll="handleScroll()" v-if="Object.keys(layout).length > 0">    
    <!-- DEFINE HEADER -->
    <Header :isScrolled="isScrolled" :isScrolledMobile="isScrolledMobile"/>
    <!-------------------->
    
    <main class="content-main">
      <router-view :key='$route.fullPath'/>
    </main>

    <!-- DEFINE MENU -->
    <Menu />
    <!-------------------->

    <!-- DEFINE FOOTER -->
    <Footer />
    <FooterMenuMobile />
    <!-------------------->
    <!--- BACKDROP -->
    <div class="backdrop_bar d-none"></div>
    <!-------------------->    
  </div>
</template>
<script>
  import Header from '@/components/Header';
  import Menu from '@/components/menus/Menu.vue';
  import Footer from '@/components/Footer';
  import FooterMenuMobile from '@/components/Footer/FooterMenuMobile.vue';
  import { mapGetters, mapActions } from 'vuex';
  import {changeLayout} from './LayoutTheme.js';

  export default {
    components: {
      Header,
      Footer,
      Menu,
      FooterMenuMobile
    },
    computed: {
      ...mapGetters('Site', ['isDesktop', 'layout'])
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      ...mapActions('Site', ['setIsDesktop', 'loadLayoutConfigs', 'loading']),      
      handleScroll(event) {
        if (event.currentTarget.innerWidth > 992) {
          if (event.currentTarget.scrollY > 100){
            this.isScrolled = true;
          } else {
            this.isScrolled = false;
          }
        } else {
          if (event.currentTarget.scrollY > 100){
            this.isScrolledMobile = true;
          } else {
            this.isScrolledMobile = false;
          }
        }
      }
    },
    async mounted() {
      if (window.innerWidth > 992){
        this.setIsDesktop(true);
      } else {
        this.setIsDesktop(false);
      }
      
      this.loading(true);
      if(!sessionStorage.getItem('layout_configs')){
        await this.loadLayoutConfigs();
      }
      await changeLayout(this.layout);
      this.loading(false);

      this.$nextTick(() => {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister()
        } })  
      });
    },
    data() {
      return {
        isScrolled: false,
        isScrolledMobile: false,
        root: null,
      }
    },
  }
</script>
<style lang="scss">

@import './assets/scss/generic/mixins.scss';
@import './assets/scss/generic/fonts.scss';
@import './assets/scss/generic/utilities.scss';

:root {
  --main-color: #CC0000;
  --main-color-light: #F21A35;
  --main-color2: #D9B48F;
  --main-color3: #C6AFA0;
  --main-color4: #5E4F47;
  --red: #CC0000;
  --red-2: #F21A35;
  --green: #4AB900;
  --white: #fff;
  --black: #000;
}
html,
body {
  margin: 0;
  width: 100%;
  color: #687188;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.content-main {
  margin-top: 130px;
  @media (max-width: 991px){
    margin-top: 173px;
  }
}
@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
    max-width: 1100px !important;
  }
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1640px !important;
  }
}
.swiper-button-next,
.swiper-button-prev {
  outline: none !important;
  &::after {
    content: none;
  }
}
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  background: 0;
  border: 0;
}
.overflow-hidden {
  overflow: hidden;
}
.backdrop_bar {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
}
a {
  text-decoration: none !important;
}
button {
  outline: none !important;
}
</style>
