<template>
  <section class="content-home-blog">
    <div class="content-home-blog-itens container">
			<div class="content-home-blog-itens-header">
				<h2 class="title">Blog</h2>
			</div>
			<div class="content-home-blog-itens-body">
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-4" v-for="n in (9)" :key="n">
						<CardBlog />
					</div>
				</div>
			</div>
    </div>
  </section>
</template>

<script>
import CardBlog from "@/components/cards/CardBlog.vue";

export default {
	components: {
		CardBlog
	}
}
</script>

<style>

</style>