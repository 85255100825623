<template>
  <router-link tag="a" :to="{name: 'BlogIntern'}" class="content-card-blog">
		<div class="content-card-blog-image" :style="{'background': `url('${require('@/assets/img/home/image-blog.jpg')}')`}">
		</div>
		<div class="content-card-blog-description">
			<p class="date">01/02/2021</p>
			<h2 class="title">Título da notícia</h2>
			<p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...</p>
			<div class="btn-know-more">
				Saiba mais
			</div>
		</div>
  </router-link>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.content-card-blog {
	display: block;
	background: var(--white);
	border: 1px solid #F5F5F5;
	border-radius: 3px;
	box-shadow: 0px 2px 6px rgb(0,0,0,0.16);
	margin-bottom: 45px;
	transition: 500ms;
	@media (max-width: 991px){
		margin-bottom: 30x;
	}
	&:hover,&:focus {
		cursor: pointer;
		opacity: 0.8;
	}
	&-image {
		height: 218px;
		width: 100%;
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: cover !important;
	}
	&-description {
		padding: 25px;
		.date {
			text-align: left;
			font-size: 14px;
			font-family: 'Font Regular';
			color: #707070;
			margin-bottom: 13px;
		}
		.title {
			text-align: left;
			font-size: 22px;
			font-family: 'Font Bold';
			color: #3E3E3E;
			margin-bottom: 5px;
		}
		.text {
			text-align: left;
			font-size: 14px;
			font-family: 'Font Regular';
			color: #707070;
			margin-bottom: 25px;
		}
		.btn-know-more {
			height: 41px;
			width: 123px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--white);
			border: 1px solid #707070;
			border-radius: 2px;
			font-size: 16px;
			font-family: 'Font Black';
			color: var(--main-color);
			transition: 500ms;
			&:hover,&:focus {
				cursor: pointer;
				background: #707070;
				color: var(--white);
			}
		}
	}
}
</style>