import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters'
import { i18n } from './i18n'

/**Plugins */
import './plugins';
import './registerServiceWorker';
import directives from './directives';
import mixins from './mixins'

Vue.use(filters);
Vue.use(directives);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mixins: [mixins],
  render: h => h(App),
}).$mount('#app')
