import ContentFile from './ContentFile';
import Money from './Money';
import RemoveP from './RemoveP';
import StripHtml from './StripHtml';

export default {
     install(Vue) {
         Vue.filter('money', Money); 
         Vue.filter('stripHtml', StripHtml);         
         Vue.filter('removeP', RemoveP); 
         Vue.filter('contentFile', ContentFile);         
     }
}