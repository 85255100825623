<template>
  <div class="blog-intern">
    <section class="content-blog-intern">
			<div class="content-blog-intern-title">
				<p class="content-blog-intern-title-text">
					Lorem Ipsum dolor sit amet, consectetur adipiscing elit
				</p>
			</div>
			<div class="content-blog-intern-date">
				<div class="content-blog-cards-information-itens-description">
					<div class="content-blog-cards-information-itens-description-date mr-lg-5">
						<svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="#d4dbdd"
								d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
								class=""></path>
						</svg>
						<p>22/10/2018</p>
					</div>
					<div class="content-blog-cards-information-itens-description-curiosities">
						<svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tags" role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
							<path fill="#d4dbdd"
								d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
								class=""></path>
						</svg>
						<p>Em <span>curiosidades</span></p>
					</div>
				</div>
			</div>
			<div class="content-news-sociais">
				<div class="content-news-sociais-itens">
					<div class="content-news-sociais-itens-element">
						<p>26</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="22">
						<g data-name="Agrupar 1" transform="translate(-832 -370)">
							<path data-name="Rounded Rectangle 3"
								d="M838 370h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3h-16a3 3 0 0 1-3-3v-3.969l-3-4.063 3-3.968v-4a3 3 0 0 1 3-3z"
								fill="#39579a"></path>
							<image data-name="Layer 21" width="6" height="11" transform="translate(843 376)"
								xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAYAAABcUvyWAAAABHNCSVQICAgIfAhkiAAAADtJREFUGJWtjTEKACAMxFLxvf5/i4uKVMHFQKEQuKAyrrkRKoP1AFROAqBcBFO0NCNguEVyI1L83fgkOpYBIdXN5VE8AAAAAElFTkSuQmCC">
							</image>
						</g>
					</svg>
				</div>
				<div class="content-news-sociais-itens">
					<div class="content-news-sociais-itens-element">
						<p>14</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22">
						<g data-name="Agrupar 2">
							<path data-name="Rounded Rectangle 3"
								d="M6 0h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3.969l-3-4.063L3 7V3a3 3 0 0 1 3-3z"
								fill="#01acee"></path>
							<path data-name=""
								d="M20.656 7.187a5.037 5.037 0 0 1-1.453.391 2.519 2.519 0 0 0 1.109-1.391 4.974 4.974 0 0 1-1.6.609 2.526 2.526 0 0 0-4.367 1.727 2.85 2.85 0 0 0 .063.578 7.171 7.171 0 0 1-5.2-2.641 2.527 2.527 0 0 0 .781 3.375 2.54 2.54 0 0 1-1.141-.32v.031a2.524 2.524 0 0 0 2.023 2.477 2.669 2.669 0 0 1-.664.086 3.184 3.184 0 0 1-.477-.039 2.527 2.527 0 0 0 2.359 1.75 5.053 5.053 0 0 1-3.133 1.078 5.2 5.2 0 0 1-.609-.031A7.183 7.183 0 0 0 19.402 8.82c0-.109 0-.219-.008-.328a5.412 5.412 0 0 0 1.262-1.305z"
								fill="#fff"></path>
						</g>
					</svg>
				</div>
				<div class="content-news-sociais-itens">
					<div class="content-news-sociais-itens-element d-none d-lg-flex">
						<p>3</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22">
						<g data-name="Agrupar 3">
							<path data-name="Rounded Rectangle 3"
								d="M6 0h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3.969l-3-4.063L3 7V3a3 3 0 0 1 3-3z"
								fill="#cd1d1f"></path>
							<path data-name=""
								d="M20 11a6 6 0 1 0-8.406 5.5 5.278 5.278 0 0 1 .1-1.383s.117-.485.774-3.266a2.5 2.5 0 0 1-.2-.953c0-.891.515-1.555 1.156-1.555a.809.809 0 0 1 .813.906 12.924 12.924 0 0 1-.531 2.125.928.928 0 0 0 .945 1.156c1.141 0 1.906-1.461 1.906-3.187a2.251 2.251 0 0 0-2.5-2.3 2.842 2.842 0 0 0-2.953 2.875 1.739 1.739 0 0 0 .4 1.18.3.3 0 0 1 .085.336c-.031.109-.094.375-.125.476a.206.206 0 0 1-.3.156 2.322 2.322 0 0 1-1.227-2.3 3.971 3.971 0 0 1 4.281-3.75 3.592 3.592 0 0 1 3.8 3.438c0 2.359-1.312 4.117-3.242 4.117a1.7 1.7 0 0 1-1.461-.75 280.864 280.864 0 0 1-.422 1.648 4.967 4.967 0 0 1-.609 1.281 5.866 5.866 0 0 0 1.7.25A6 6 0 0 0 20 11z"
								fill="#fff"></path>
						</g>
					</svg>
				</div>
				<div class="content-news-sociais-itens">
					<div class="content-news-sociais-itens-element d-none d-lg-flex">
						<p>69</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22">
						<g data-name="Agrupar 4">
							<path data-name="Rounded Rectangle 3"
								d="M6 0h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3.969l-3-4.063L3 7V3a3 3 0 0 1 3-3z"
								fill="#d34836"></path>
							<path data-name=""
								d="M14.868 14.524c0 1.173-1.112 1.574-2.11 1.574-1.072 0-2.519-.5-2.519-1.782 0-1.393 1.634-1.741 2.733-1.741a2.866 2.866 0 0 1 .335.013c.683.476 1.561.998 1.561 1.936zm-.8-5.774c0 .817-.315 1.48-1.233 1.48-1.192 0-1.862-1.594-1.862-2.612a1.4 1.4 0 0 1 1.306-1.567c1.236 0 1.785 1.681 1.785 2.699zm-.877-3.182a6.045 6.045 0 0 0-1.253.134 2.776 2.776 0 0 0-2.2 2.626 2.344 2.344 0 0 0 2.425 2.3 3.774 3.774 0 0 0 .469-.033 1.741 1.741 0 0 0-.147.576 1.7 1.7 0 0 0 .429 1c-1.2.034-3.062.342-3.658 1.548a2.055 2.055 0 0 0-.248.9c0 1.554 1.809 2.09 3.061 2.09a4.274 4.274 0 0 0 2.813-.877 2.464 2.464 0 0 0 .938-1.889c0-1.876-2.01-2.217-2.01-3.081 0-.884 1.5-1.018 1.5-2.793a2.339 2.339 0 0 0-1-1.976h.9l.9-.529zm6.96 1.715h-1.715V5.568h-.857v1.715h-1.714v.857h1.714v1.715h.857V8.14h1.715z"
								fill="#fff"></path>
						</g>
					</svg>
				</div>
				<div class="content-news-sociais-itens">
					<div class="content-news-sociais-itens-element d-none d-lg-flex">
						<p>165</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22">
						<g data-name="Agrupar 5">
							<path data-name="Rounded Rectangle 3"
								d="M6 0h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3.969l-3-4.063L3 7V3a3 3 0 0 1 3-3z"
								fill="#1783bc"></path>
							<path data-name=""
								d="M11.338 8.9H9.127v6.638h2.211zm.141-2.05a1.243 1.243 0 1 0-1.26 1.146h.014a1.145 1.145 0 0 0 1.246-1.148zm7.811 4.883c0-2.036-1.085-2.988-2.539-2.988a2.187 2.187 0 0 0-2 1.119h.014V8.9h-2.2s.026.623 0 6.638h2.2v-3.7a1.676 1.676 0 0 1 .073-.543 1.209 1.209 0 0 1 1.132-.8c.8 0 1.119.61 1.119 1.5v3.55h2.2z"
								fill="#fff"></path>
						</g>
					</svg>
				</div>
			</div>
			<div class="content-blog-intern-image">
				<img class="img-fluid" src="@/assets/img/vehicle-intern/image-vehicle-intern.png" alt="image vehicle blog" title="image vehicle blog">
			</div>
		</section>
		<section class="content-information-new-intern container">
			<div class="content-information-new-intern-header">
				<p class="content-information-new-intern-header-title">Lorem ipsum dolor sit amet</p>
			</div>
			<div class="content-information-new-intern-body">
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at erat nisl. Nulla in malesuada leo, id interdum quam. Ut eu ipsum sed nisi accumsan laoreet in vitae odio. Nunc a elementum justo, non pharetra tellus. Fusce volutpat orci non lorem dictum, sed dictum tellus hendrerit. Nunc dignissim imperdiet eros. Proin finibus elit in orci lobortis, in condimentum sapien finibus. Donec tempor urna eu nulla facilisis, ut porta velit egestas.</p>
				<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed placerat dui eget eros hendrerit, id mattis dui auctor. Mauris enim leo, pulvinar nec semper vitae, dignissim hendrerit mi. Ut elementum odio finibus tempor tincidunt. In nec viverra nisi, a dictum leo. Aliquam nec nibh consectetur ex euismod bibendum vitae in odio. Pellentesque diam nibh, maximus non malesuada et, commodo eu quam.</p>
				<div class="content-information-new-intern-body-itens-image">
					<img class="img-fluid" src="@/assets/img/vehicle-intern/image-vehicle-intern.png" alt="image vehicle blog" title="image vehicle blog">
				</div>
			</div>
			<div class="content-information-new-intern-header">
					<p class="content-information-new-intern-header-title">Lorem ipsum dolor sit amet</p>
			</div>
			<div class="content-information-new-intern-body">
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at erat nisl. Nulla in malesuada leo, id interdum quam. Ut eu ipsum sed nisi accumsan laoreet in vitae odio. Nunc a elementum justo, non pharetra tellus. Fusce volutpat orci non lorem dictum, sed dictum tellus hendrerit. Nunc dignissim imperdiet eros. Proin finibus elit in orci lobortis, in condimentum sapien finibus. Donec tempor urna eu nulla facilisis, ut porta velit egestas.</p>
					<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed placerat dui eget eros hendrerit, id mattis dui auctor. Mauris enim leo, pulvinar nec semper vitae, dignissim hendrerit mi. Ut elementum odio finibus tempor tincidunt. In nec viverra nisi, a dictum leo. Aliquam nec nibh consectetur ex euismod bibendum vitae in odio. Pellentesque diam nibh, maximus non malesuada et, commodo eu quam.</p>
			</div>
			<div class="content-information-new-intern-body">
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at erat nisl. Nulla in malesuada leo, id interdum quam. Ut eu ipsum sed nisi accumsan laoreet in vitae odio. Nunc a elementum justo, non pharetra tellus. Fusce volutpat orci non lorem dictum, sed dictum tellus hendrerit. Nunc dignissim imperdiet eros. Proin finibus elit in orci lobortis, in condimentum sapien finibus. Donec tempor urna eu nulla facilisis, ut porta velit egestas.</p>
					<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed placerat dui eget eros hendrerit, id mattis dui auctor. Mauris enim leo, pulvinar nec semper vitae, dignissim hendrerit mi. Ut elementum odio finibus tempor tincidunt. In nec viverra nisi, a dictum leo. Aliquam nec nibh consectetur ex euismod bibendum vitae in odio. Pellentesque diam nibh, maximus non malesuada et, commodo eu quam.</p>
			</div>
		</section>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.content-blog-intern {
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
	align-items: center;
	@media (max-width: 991px){
		padding: 30px 15px;
	}
  &-title {
    &-text {
      font-size: 30px;
      font-family: 'Font Medium';
      color: #111;
      margin-bottom: 0;
			line-height: 1;
			@media (max-width: 991px){
				font-size: 22px;
				text-align: center;
			}
    }
  }
  &-image {
		margin-top: 50px;
		text-align: center;
		@media (max-width: 991px){
			margin-top: 30px;
		}
  }
	&-date {
		.content-blog-cards-information-itens{
			&-description {
				margin-top: 10px;
				display: flex;
				justify-content: space-between;
				&-date {
					display: flex;
					align-items: center;
					margin-right: 30px;
					p {
						font-size: 16px;
						font-family: 'Font Medium';
						color: var(--black);
						margin: 0;
						margin-left: 5px;
						@media (max-width: 991px){
							font-size: 14px;
						}
					}
				}
				&-curiosities {
					display: flex;
					align-items: center;
					p {
						font-size: 16px;
						font-family: 'Font Medium';
						color: var(--black);
						margin: 0;
						margin-left: 5px;
						@media (max-width: 991px){
							font-size: 14px;
						}
						span {
							color: var(--main-color);
						}
					}
				}
			}
			&-text {
				margin-top: 10px;
				p {
					&:first-of-type{
						font-size: 20px;
						color: var(--black);
						font-family: 'Font Bold';
						margin: 0;
						margin-bottom: 10px;
						line-height: 1.4;
						@media (max-width: 991px){
							font-size: 18px;
							font-family: 'Font Medium';
						}
					}
					&:last-of-type {
						font-size: 16px;
						color: #333333;
						font-family: 'Font Light';
						@media (max-width: 991px){
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
.content-news-sociais {
  margin-top: 20px;
  display: flex;
  &-itens {
    display: flex;
    margin-right: 3px;
    margin-left: 3px;
    &:first-of-type {
      margin-left: initial;
    }
    &-element {
      border: 1px solid #e3e3e3;
      width: 35px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      margin-right: 5px;
      p {
        margin-bottom: 0;
        font-size: 11px;
        font-family: "Font Regular";
        color: #7d7d7d;
      }
    }
  }
}
.content-information-new-intern {
	margin-bottom: 30px;
	padding-bottom: 20px;
	border-bottom: 1px solid #d6d6d6;
	text-align: left;
  &-header {
		margin-bottom: 10px;
		@media (max-width: 991px){
			margin-top: 20px;
		}
    &-title {
      font-size: 24px;
      font-family: 'Font Bold';
      color: #111;
			margin-bottom: 0;
			@media (max-width: 991px){
				font-size: 20px;
			}
    }
  }
  &-body {
    p {
      font-size: 16px;
      font-family: "Font Regular";
      color: #111;
      margin-bottom: 15px;
			line-height: 2;
			@media (max-width: 991px){
				font-size: 14px;
			}
    }
    &-itens {
			display: flex;
			justify-content: space-between;
			@media (max-width: 991px){
				flex-direction: column;
			}
      &-div {
				width: 42%;
				@media (max-width: 991px){
					width: 100%;
				}
        p {
          font-size: 16px;
          font-family: "Font Regular";
          color: #111;
          margin-bottom: 15px;
					line-height: 2;
					@media (max-width: 991px){
						font-size: 14px;
					}
        }
			}
			&-image {
				text-align: center;
				img {
					max-width: 450px;
					@media (max-width: 991px){
						max-width: 100%;
					}
				}
			}
    }
  }
}
</style>