import store from '@/store';

export const THEME = {
    'honda-moto': {
        mainColor: '#CC0000',
        mainColorLight: '#F21A35',
        mainColor2: '#D9B48F',
        mainColor3: '#C6AFA0',
        mainColor4: '#5E4F47',        
        imageHome: {
            image1: require('@/assets/img/home/honda-moto/solution-1.png'),
            image2: require('@/assets/img/home/honda-moto/solution-2.png'),
            image3: require('@/assets/img/home/honda-moto/solution-3.png'),
            image4: require('@/assets/img/home/honda-moto/solution-4.png'),
        },
        images: {
            directSales: require('@/assets/img/banner/banner-direct-sales.jpg'),
            vehicleTestDrive: require('@/assets/img/banner/banner-direct-sales.jpg'),
            vehicleConsortium: require('@/assets/img/consortium/image-consortium.jpg'),
            vehicleSchedule: require('@/assets/img/banner/banner-services.jpg'),
            about: require('@/assets/img/banner/banner-services.jpg'),
            vehicleParts: require('@/assets/img/piece-acessories/banner-piece-acessories.jpg'),
        },
        iconVehicle: `<svg xmlns="http://www.w3.org/2000/svg" width="35.281" height="19.886" viewBox="0 0 35.281 19.886" > <g transform="translate(0 -106.101)"> <g transform="translate(0 106.101)"> <path d="M5.813,219.934a5.813,5.813,0,1,0,5.814,5.813A5.813,5.813,0,0,0,5.813,219.934Zm0,9.043a3.23,3.23,0,1,1,3.23-3.23A3.233,3.233,0,0,1,5.813,228.977Z" transform="translate(0 -211.675)"/> <path d="M103.782,114.36a5.8,5.8,0,0,0-1.874.311l-1.6-2.491a8.294,8.294,0,0,1,1.048-.666v-2.86l-2.568,1.157-2.382-3.71H91.752l-.855.712,3.9,1.271,1.755,2.733-4.632,2.169L87.395,110l-7.267-1.157V110.7l5.339,4.091,3.306,6.864h6.411l1.656-6.015A22.679,22.679,0,0,1,98.47,113.8l1.357,2.114a5.812,5.812,0,1,0,3.955-1.554Zm0,9.043a3.227,3.227,0,0,1-2.517-5.25l1.717,2.674,2.038-1.308-1.637-2.55a3.229,3.229,0,1,1,.4,6.434Z" transform="translate(-74.315 -106.101)"/> </g> </g> </svg>`,
    },
    'honda-car': {
        mainColor: '#CC0000',
        mainColorLight: '#F21A35',
        mainColor2: '#D9B48F',
        mainColor3: '#C6AFA0',
        mainColor4: '#5E4F47',
        iconVehicle: `<svg version="1.1" id="Capa_1" width="35.281" height="26.886" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 394.648 394.648" style="enable-background:new 0 0 394.648 394.648;" xml:space="preserve"><path style="stroke:#000000;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;" d="M391.909,145.036c-0.938-2.731-4.263-4.855-7.039-4.855c-12.021,0-24.04,0-36.061,0c-2.775,0-6.101,2.124-7.04,4.855c-0.918,2.673-1.604,5.058-2.06,7.301l-24.088-66.641c-3.973-10.989-14.495-18.372-26.179-18.372H105.209c-11.685,0-22.205,7.383-26.18,18.374l-24.089,66.647c-0.457-2.245-1.143-4.633-2.062-7.309c-0.938-2.731-4.264-4.855-7.039-4.855c-12.02,0-24.04,0-36.061,0c-2.775,0-6.101,2.124-7.039,4.855c-3.652,10.633-3.65,16.72,0.001,27.354c0.938,2.731,4.264,4.855,7.039,4.855c12.021,0,24.041,0,36.061,0c0.034,0,0.07-0.009,0.105-0.01l-0.544,1.507c-7.353,2.443-12.662,9.365-12.662,17.539v55.805v4.599v61.752c0,4.909,3.978,8.887,8.887,8.887h44.727c4.908,0,8.887-3.978,8.887-8.887v-43.262H299.41v43.262c0,4.909,3.979,8.887,8.887,8.887h44.727c4.909,0,8.887-3.978,8.887-8.887v-61.752v-4.599v-55.805c0-8.172-5.307-15.093-12.657-17.538l-0.545-1.508c0.033,0.001,0.068,0.009,0.101,0.009c12.021,0,24.04,0,36.061,0c2.776,0,6.101-2.124,7.039-4.855C395.561,161.756,395.559,155.669,391.909,145.036z M108.612,100.028h177.402l28.108,77.762H80.505L108.612,100.028z M113.56,232.571H76.142c-8.028,0-14.535-6.507-14.535-14.535c0-8.027,6.506-14.535,14.535-14.535h37.418c8.027,0,14.535,6.507,14.535,14.535C128.096,226.064,121.587,232.571,113.56,232.571z M318.505,232.571h-37.418c-8.027,0-14.535-6.507-14.535-14.535c0-8.027,6.508-14.535,14.535-14.535h37.418c8.028,0,14.535,6.507,14.535,14.535C333.04,226.064,326.533,232.571,318.505,232.571z"/></svg>`,
    },
    'yamaha-moto': {
        mainColor: '#163685',
        mainColorLight: '#2947D3',
        mainColor2: '#D9B48F',
        mainColor3: '#C6AFA0',
        mainColor4: '#5E4F47',
        iconVehicle: `<svg xmlns="http://www.w3.org/2000/svg" width="35.281" height="19.886" viewBox="0 0 35.281 19.886" > <g transform="translate(0 -106.101)"> <g transform="translate(0 106.101)"> <path d="M5.813,219.934a5.813,5.813,0,1,0,5.814,5.813A5.813,5.813,0,0,0,5.813,219.934Zm0,9.043a3.23,3.23,0,1,1,3.23-3.23A3.233,3.233,0,0,1,5.813,228.977Z" transform="translate(0 -211.675)"/> <path d="M103.782,114.36a5.8,5.8,0,0,0-1.874.311l-1.6-2.491a8.294,8.294,0,0,1,1.048-.666v-2.86l-2.568,1.157-2.382-3.71H91.752l-.855.712,3.9,1.271,1.755,2.733-4.632,2.169L87.395,110l-7.267-1.157V110.7l5.339,4.091,3.306,6.864h6.411l1.656-6.015A22.679,22.679,0,0,1,98.47,113.8l1.357,2.114a5.812,5.812,0,1,0,3.955-1.554Zm0,9.043a3.227,3.227,0,0,1-2.517-5.25l1.717,2.674,2.038-1.308-1.637-2.55a3.229,3.229,0,1,1,.4,6.434Z" transform="translate(-74.315 -106.101)"/> </g> </g> </svg>`,
    }
};

export async function changeLayout(layout) {
    let root = document.documentElement;
    let classTheme = THEME[layout.company.brand.slug];
    root.style.setProperty("--main-color", classTheme.mainColor);
    root.style.setProperty("--main-color-light", classTheme.mainColorLight);
    root.style.setProperty("--main-color2", classTheme.mainColor2);
    root.style.setProperty("--main-color3", classTheme.mainColor3);
    root.style.setProperty("--main-color4", classTheme.mainColor4);        
}

export function getThemeActive() {
    let layout = store.getters['Site/layout'];        
    let classTheme = THEME[layout.company.brand.slug];
    
    return classTheme;
}
