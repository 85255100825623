<template>
  <header class="content-header" :class="{'content-header-scrolled': isScrolled || isScrolledMobile}">
    <div class="content-header-itens">
      <div class="content-header-itens-div">
        <div class="content-header-itens-div-element container">
          <div class="content-logo">
            <router-link tag="a" to="/" class="content-header-itens-logo">
              <img
                v-if="layout.logo_path"
                class="img-fluid"
                :src="layout.logo_path"
                :alt="'Logo '+layout.company.name"
                :title="'Logo '+layout.company.name"
              />
            </router-link>
            <button class="content-menu-desktop d-none d-lg-block" @click="menuDesktopOpen()" :class="{'btn-open': openMenuDesktop}">
              <img
                class="img-fluid"
                src="@/assets/img/header/burguer.png"
                alt="icon burguer"
                title="icon burguer"
              />
              <img
                class="img-fluid icon-arrow-down"
                src="@/assets/img/header/arrow-down.png"
                alt="arrow down"
                title="arrow down"
              />
            </button>
            <div class="content-menu d-lg-none">
              <a class="content-menu-item btn-menu" @click="menuOpen(true)">
                <span></span>
                <p>Menu</p>
              </a>
            </div>
          </div>
          <div class="content-header-itens-div-elements">
            <!--  -->
            <ListUnits />
            <!--  -->
            <div class="content-btns" :class="{'content-btns-scrolled': isScrolledMobile}">
              <template v-if="!activeFormFloating">
                <button class="content-btns-cotation" 
                  @click="toggleFormFixed()">
                  {{ $t('header.button_cotation')|stripHtml }}
                </button>                
              </template>
              <template v-else>
                <button class="content-btns-cotation" @click="toggleFormFixed()">
                  <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31.919"
              height="31.917"
              viewBox="0 0 31.919 31.917"
            >
              <g transform="translate(0 -0.016)">
                <g transform="translate(0 0.016)">
                  <path
                    d="M19.475,15.974,31.409,4.04a1.749,1.749,0,0,0,0-2.469L30.363.526a1.75,1.75,0,0,0-2.469,0L15.96,12.459,4.025.526a1.749,1.749,0,0,0-2.468,0L.511,1.571a1.747,1.747,0,0,0,0,2.469L12.445,15.974.511,27.908a1.751,1.751,0,0,0,0,2.469l1.046,1.046a1.749,1.749,0,0,0,2.468,0L15.96,19.489,27.894,31.423a1.732,1.732,0,0,0,1.234.51h0a1.732,1.732,0,0,0,1.234-.51l1.046-1.046a1.75,1.75,0,0,0,0-2.469Z"
                    transform="translate(0 -0.016)"
                  />
                </g>
              </g>
                  </svg>
                  {{ $t('header.button_close')|stripHtml }}
                </button>
              </template>
              <button class="content-btns-whatsapp btn-content-whatsapp">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.451"
                  height="32.482"
                  viewBox="0 0 32.451 32.482"
                >
                  <g transform="translate(0)">
                    <path
                      d="M27.655,4.717a16.234,16.234,0,0,0-22.878,0A15.935,15.935,0,0,0,0,16.091V16.1a16.017,16.017,0,0,0,1.976,7.6L.044,32.482l8.883-2.02a16.258,16.258,0,0,0,7.292,1.732h.006A16.182,16.182,0,0,0,27.672,27.48a15.925,15.925,0,0,0-.017-22.764ZM16.225,29.659h-.006a13.7,13.7,0,0,1-6.513-1.651l-.42-.228L3.379,29.123l1.283-5.831-.247-.426A13.551,13.551,0,0,1,2.535,16.1,13.685,13.685,0,0,1,25.866,6.513,13.476,13.476,0,0,1,29.915,16.1,13.643,13.643,0,0,1,16.225,29.659Zm0,0"
                    />
                    <path
                      d="M141.867,141.863h-.711a1.366,1.366,0,0,0-.99.463,4.142,4.142,0,0,0-1.3,3.085,7.184,7.184,0,0,0,1.515,3.826c.186.247,2.567,4.1,6.34,5.584,3.135,1.232,3.773.987,4.454.925a3.748,3.748,0,0,0,2.505-1.758,3.083,3.083,0,0,0,.217-1.759c-.093-.154-.34-.247-.711-.431s-2.19-1.1-2.53-1.219-.588-.185-.835.186-.976,1.225-1.193,1.472-.433.278-.8.093a10.261,10.261,0,0,1-2.972-1.843,11.372,11.372,0,0,1-2.086-2.6c-.216-.37-.023-.57.163-.755.167-.166.392-.392.578-.608a2.5,2.5,0,0,0,.362-.617.679.679,0,0,0-.031-.648c-.093-.185-.805-2.014-1.135-2.746h0C142.424,141.895,142.131,141.874,141.867,141.863Zm0,0"
                      transform="translate(-130.057 -132.863)"
                    />
                  </g>
                </svg>
                WHATSAPP
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-header-itens-links d-none d-lg-block" :class="{'open-menu': openMenuDesktop}">
        <LinksNavigation />
      </div>
    </div>
    <FormFloating :activeFormFloating="activeFormFloating"/>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormFloating from "@/components/Forms/FormFloat.vue";
import LinksNavigation from './LinksNavigation.vue';
import ListUnits from './ListUnits.vue';

export default {
  name: 'Header',
  components: {
    FormFloating,
    LinksNavigation,
    ListUnits,
  },
  props: {
    isScrolled: Boolean,
    isScrolledMobile: Boolean,
  },  
  computed: {
    ...mapGetters('Site', ['isDesktop', 'activeMenuFooter', 'activeFormFloating', 'layout'])
  },
  methods: {
    ...mapActions('Site', ['toggleFormFixed', 'menuOpen', 'setActiveMenuFooter']),
    menuDesktopOpen(){
      if (this.openMenuDesktop){
        this.openMenuDesktop = false;
      } else {
        this.openMenuDesktop = true;
      }
    },
  },
  data() {
    return {
      openMenuDesktop: false,
    };
  },
};
</script>

<style lang="scss">
.content-header {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.16);
  z-index: 5;
  transition: 500ms;
  @media (max-width: 991px) {
    background: var(--white);
    padding-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
    border-radius: 8px;
  }
  &-itens {
    &-div {
      position: relative;
      width: 100%;
      height: 80px;
      background: var(--white);
      transition: 500ms;
      z-index: 2;
      @media (max-width: 991px){
        height: initial;
        background: 0;
      }
      &-element {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media (max-width: 991px){
          flex-direction: column;
        }
        .content-logo {
          display: flex;
          align-items: center;
          @media (max-width: 991px){
            justify-content: space-between;
            width: 100%;
            padding: 10px 0px;
          }
        }
      }
      &-elements {
        display: flex;
        align-items: center;
        @media (max-width: 991px){
          flex-direction: column;
          width: 100%;
        }
        .slider-telphone {
          transition: 500ms;
          text-decoration: none;
          display: block;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
          &-name {
            font-size: 17px;
            font-family: "Font Bold";
            color: #262628;
            margin-bottom: 0;
            @media (max-width: 991px){
              font-size: 14px;
            }
          }
          &-telphones {
            font-size: 15px;
            font-family: "Font Regular";
            color: #9b9b9b;
            margin-bottom: 0;
            @media (max-width: 991px){
              font-size: 14px;
            }
          }
        }
        .content-btns {
          display: flex;
          align-items: center;
          @media (max-width: 991px){
            width: 100%;
            margin-top: 10px;
            height: 50px;
            transition: height 500ms;
          }
          &-cotation {
            height: 55px;
            width: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--main-color);
            border: 1px solid var(--main-color);
            border-radius: 15px;
            font-size: 20px;
            font-family: 'Font Regular';
            text-transform: uppercase;
            color: var(--white);
            margin-right: 13px;
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            transition: 500ms;
            @media (max-width: 991px){
              width: calc(50% - 6.5px);
              height: 50px;
              font-size: 16px;
              border-radius: 10px;
            }
            @media (min-width: 992px) and (max-width: 1400px) {
              width: 180px;
              height: 50px;
              font-size: 18px;
            }
            svg {
              margin-right: 15px;
              fill: var(--white);
              max-width: 25px;
            }
            &:hover,&:focus {
              cursor: pointer;
              background: var(--white);
              color: var(--main-color);
              svg {
                fill: var(--main-color);
              }
            }
          }
          &-whatsapp {
            height: 55px;
            width: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--green);
            border: 1px solid var(--green);
            border-radius: 15px;
            font-size: 20px;
            font-family: 'Font Regular';
            text-transform: uppercase;
            color: var(--white);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            transition: 500ms;
            @media (max-width: 991px){
              width: calc(50% - 6.5px);
              height: 50px;
              font-size: 16px;
              border-radius: 10px;
            }
            @media (min-width: 992px) and (max-width: 1400px) {
              width: 180px;
              height: 50px;
              font-size: 18px;
            }
            svg {
              margin-right: 13px;
              fill: var(--white);
              transition: 500ms;
              @media (max-width: 576px){
                margin-right: 7px;
                max-width: 20px;
              }
            }
            &:hover,&:focus {
              cursor: pointer;
              background: var(--white);
              color: var(--green);
              svg {
                fill: var(--green);
              }
            }
          }
          &-scrolled {
            opacity: 0;
            height: 0;
            margin-top: 0;
            pointer-events: none;
          }
        }
        .swiper-telphones {
          width: 500px;
          margin-right: 65px;
          padding: 0px 30px;
          @media (min-width: 992px) and (max-width: 1199px){
            width: 200px;
            margin-right: 30px;
          }
          @media (min-width: 1200px) and (max-width: 1400px){
            width: 350px;
            margin-right: 50px;
          }
          @media (max-width: 991px){
            width: 100%;
            margin-right: 0;
          }
        }
        .swiper-button-prev, .swiper-button-next {
          background: 0 !important;
          border: 0 !important;
          @media (max-width: 576px){
            svg {
              max-width: 10px;
            }
          }
        }
        .swiper-button-next {
          right: -4px;
        }
        .swiper-button-prev {
          left: -4px;
        }
      }
    }
    &-logo {
      img {
        @media (max-width: 991px) {
          max-width: 100px;
        }
        @media (min-width: 992px) and (max-width: 1199px){
          max-width: 200px;
        }
      }
    }
    &-links {
      position: relative;
      height: 50px;
      background: var(--main-color-light);
      transition: 500ms ease-in-out;
      margin-top: 0;
      z-index: 1;
      &-div {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .btn-links {
        font-size: 15px;
        font-family: 'Font Medium';
        color: var(--white) !important;
        margin-right: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        transition: 500ms;
        &:hover,&:focus {
          cursor: pointer;
          opacity: 0.9;
        }
        @media (min-width: 992px) and (max-width: 1199px){
          margin-right: 20px;
        }
      }
    }
  }
  &-scrolled {
    @media (max-width: 991px){
      background: #EDEDE3;
    }
    @media (min-width: 992px){
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 9px;
        width: 298px;
        background: var(--main-color-light);
      }
      .content-header-itens-div {
        background: #EDEDE3;
      }
      .content-header-itens-links {
        margin-top: -50px;
      }
      .content-menu-desktop {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
}
.content-menu {
  &-item {
    width: 32px;
    display: block;
    padding-top: 12px;
    text-decoration: none !important;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    span {
      width: 100%;
      height: 3px;
      background-color: var(--main-color);
      display: block;
      position: relative;
      transform-origin: center;
      transition: 0.5s ease-in-out;
      margin-bottom: 5px;
      margin-right: 30px;
      &::before {
        content: "";
        transition: 0.5s ease-in-out;
        top: -9px;
        display: block;
        background-color: var(--main-color);
        position: absolute;
        height: 100%;
        width: 100%;
      }
      &::after {
        content: "";
        transition: 0.5s ease-in-out;
        bottom: -9px;
        display: block;
        background-color: var(--main-color);
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
    p {
      font-size: 10px;
      color: var(--main-color);
      font-family: "Font Light";
      margin-bottom: 0px;
      margin-top: 12px;
      text-align: center;
    }
    .active {
      background: 0;
      &::before {
        content: "";
        top: 0 !important;
        transform: rotate(45deg) !important;
      }
      &::after {
        content: "";
        bottom: 0 !important;
        transform: rotate(-45deg) !important;
      }
    }
  }
  &-desktop {
    margin-left: 30px;
    background: 0;
    border: 0;
    opacity: 0;
    pointer-events: none;
    // padding-top: 10px;
    transition: 500ms;
    img {
      max-width: 28px;
    }
    &:hover{
      cursor: pointer;
      opacity: 0.8;
    }
    .icon-arrow-down {
      margin-left: 5px;
      transition: 500ms;
    }
  }
}
.btn-open {
  .icon-arrow-down {
    transform: rotate(180deg);
  }
}
.open-menu {
  margin-top: 0 !important;
}
</style>