<template>
  <aside class="content-menu-footer d-lg-none" :class="{ 'content-menu-footer-active': activeMenuFooter }">
    <div class="content-menu-footer-itens">
      <div class="content-menu-footer-itens-elements">
        <a class="content-menu-footer-itens-elements-links" @click="tabMenuActive = 'new'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'new'}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35.281"
            height="19.886"
            viewBox="0 0 35.281 19.886"
          >
            <g transform="translate(0 -106.101)">
              <g transform="translate(0 106.101)">
                <path
                  d="M5.813,219.934a5.813,5.813,0,1,0,5.814,5.813A5.813,5.813,0,0,0,5.813,219.934Zm0,9.043a3.23,3.23,0,1,1,3.23-3.23A3.233,3.233,0,0,1,5.813,228.977Z"
                  transform="translate(0 -211.675)"
  
                />
                <path
                  d="M103.782,114.36a5.8,5.8,0,0,0-1.874.311l-1.6-2.491a8.294,8.294,0,0,1,1.048-.666v-2.86l-2.568,1.157-2.382-3.71H91.752l-.855.712,3.9,1.271,1.755,2.733-4.632,2.169L87.395,110l-7.267-1.157V110.7l5.339,4.091,3.306,6.864h6.411l1.656-6.015A22.679,22.679,0,0,1,98.47,113.8l1.357,2.114a5.812,5.812,0,1,0,3.955-1.554Zm0,9.043a3.227,3.227,0,0,1-2.517-5.25l1.717,2.674,2.038-1.308-1.637-2.55a3.229,3.229,0,1,1,.4,6.434Z"
                  transform="translate(-74.315 -106.101)"
  
                />
              </g>
            </g>
          </svg>
          <p>Novos</p>
        </a>
        <a class="content-menu-footer-itens-elements-links" @click="tabMenuActive = 'new-ones'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'new-ones'}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.144"
            height="26.144"
            viewBox="0 0 26.144 26.144"
          >
            <g transform="translate(0 0)">
              <path
                d="M175.814,124.972l3.611-10.832a.766.766,0,1,0-1.453-.484l-3.611,10.832a.766.766,0,1,0,1.453.484Z"
                transform="translate(-165.42 -107.354)"

              />
              <path
                d="M266.831,193.8a2.3,2.3,0,1,0-2.3-2.3A2.3,2.3,0,0,0,266.831,193.8Zm0-3.064a.766.766,0,1,1-.766.766A.767.767,0,0,1,266.831,190.735Z"
                transform="translate(-251.025 -179.541)"

              />
              <path
                d="M97.125,189.2a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,97.125,189.2Zm0,3.064a.766.766,0,1,1,.766-.766A.767.767,0,0,1,97.125,192.267Z"
                transform="translate(-89.985 -179.541)"

              />
              <path
                d="M25.991,9.5,23.32,5.937l.1-.729a.766.766,0,0,0-.217-.65L21.583,2.933l1.625-1.625A.766.766,0,1,0,22.125.225L20.5,1.849,18.875.225a.767.767,0,0,0-.65-.217L10.643,1.091a.766.766,0,0,0-.433.217L.821,10.7a2.812,2.812,0,0,0,0,3.972l7.944,7.944a2.8,2.8,0,0,0,.529.415v.311A2.812,2.812,0,0,0,12.1,26.145H23.336a2.812,2.812,0,0,0,2.808-2.808V9.958a.766.766,0,0,0-.153-.46ZM1.9,13.584a1.278,1.278,0,0,1,0-1.805l9.207-9.207,6.951-.993,1.354,1.354L18.333,4.016l-.542-.542a.766.766,0,1,0-1.083,1.083l2.166,2.166a.766.766,0,0,0,1.083-1.083L19.417,5.1,20.5,4.016,21.854,5.37l-.993,6.951-9.207,9.207a1.277,1.277,0,0,1-1.805,0Zm22.708,9.752a1.278,1.278,0,0,1-1.277,1.277H12.1a1.278,1.278,0,0,1-1.273-1.182,2.8,2.8,0,0,0,1.907-.819l9.388-9.388a.766.766,0,0,0,.217-.433l.673-4.709,1.6,2.132Z"
                transform="translate(0 0)"

              />
            </g>
          </svg>
          <p>Seminovos</p>
        </a>
        <button class="btn-expand-form" @click="(setActiveMenuFooter(!activeMenuFooter), activeMenuFooter ? tabMenuActive = 'new' : tabMenuActive = null)">
          <template v-if="!activeMenuFooter">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34.334"
              height="38.576"
              viewBox="0 0 34.334 38.576"
            >
              <g transform="translate(-34.503 -30.859)">
                <path
                  d="M73.786,58.3l-2.628-8.465a.727.727,0,0,0-.674-.512l-3.958-.111a.728.728,0,0,0-.595-.407l-4.058-.332a.727.727,0,0,0-.676-.511l-3.947-.1-1.97-6.344a2.844,2.844,0,1,0-5.432,1.688l4.23,13.622-2.408-2.191a2.923,2.923,0,1,0-3.841,4.408l3.357,3.014h0v0a11.066,11.066,0,0,1,1.866,2.517c.888,1.455,1.726,2.826,3.071,2.9,1.768.1,2.192,1.237,2.847,3.412l.508,1.648a.728.728,0,0,0,.911.48c.694-.216.577-.593.142-1.994l-.168-.553c-.605-2.01-1.291-4.287-4.16-4.445-.582-.032-1.287-1.187-1.91-2.206a12.314,12.314,0,0,0-2.138-2.839h0l-.006-.005h0L48.79,57.954a1.468,1.468,0,0,1,.851-2.579,1.437,1.437,0,0,1,1.062.349l4.373,3.988c.6.512,1.46.1,1.184-.753l-5.026-16.18a1.39,1.39,0,0,1,2.654-.826l3.936,12.671a.728.728,0,1,0,1.389-.431l-1.51-4.86,2.933.074,1.836,5.911a.728.728,0,0,0,.694.512.735.735,0,0,0,.216-.033.727.727,0,0,0,.478-.91l-1.525-4.913,2.986.244,1.7,5.471a.728.728,0,0,0,.694.512.735.735,0,0,0,.216-.033.727.727,0,0,0,.478-.911l-1.42-4.574,2.934.082L72.4,58.733a3.036,3.036,0,0,1-.516,2.778,5.83,5.83,0,0,0-.9,5.351l.6,1.924a.728.728,0,0,0,.694.512.736.736,0,0,0,.216-.033.727.727,0,0,0,.478-.91l-.6-1.924a4.38,4.38,0,0,1,.65-4.019,4.489,4.489,0,0,0,.762-4.11Z"
                  transform="translate(-5.152 -3.62)"
                  fill="#fff"
                />
                <path
                  d="M41.783,43.9a.728.728,0,0,0-1.029,0l-1.777,1.777V33.343l1.777,1.777a.728.728,0,0,0,1.029-1.029l-3.019-3.019a.728.728,0,0,0-1.029,0l-3.019,3.019a.727.727,0,1,0,1.028,1.029l1.778-1.778V45.681L35.744,43.9a.728.728,0,0,0-1.028,1.028l3.019,3.019a.728.728,0,0,0,1.029,0l3.019-3.019a.727.727,0,0,0,0-1.028Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31.919"
              height="31.917"
              viewBox="0 0 31.919 31.917"
            >
              <g transform="translate(0 -0.016)">
                <g transform="translate(0 0.016)">
                  <path
                    d="M19.475,15.974,31.409,4.04a1.749,1.749,0,0,0,0-2.469L30.363.526a1.75,1.75,0,0,0-2.469,0L15.96,12.459,4.025.526a1.749,1.749,0,0,0-2.468,0L.511,1.571a1.747,1.747,0,0,0,0,2.469L12.445,15.974.511,27.908a1.751,1.751,0,0,0,0,2.469l1.046,1.046a1.749,1.749,0,0,0,2.468,0L15.96,19.489,27.894,31.423a1.732,1.732,0,0,0,1.234.51h0a1.732,1.732,0,0,0,1.234-.51l1.046-1.046a1.75,1.75,0,0,0,0-2.469Z"
                    transform="translate(0 -0.016)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </template>
        </button>
        <a class="content-menu-footer-itens-elements-links" @click="tabMenuActive = 'revision'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'revision'}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.483"
            height="24.576"
            viewBox="0 0 24.483 24.576"
          >
            <g transform="translate(-0.903 -0.001)">
              <path
                d="M25.362,5.974a.565.565,0,0,0-.961-.351L21.224,8.8,17.7,7.685,16.587,4.163,19.764.987a.564.564,0,0,0-.351-.962A6.534,6.534,0,0,0,12.737,8.85c-.1.081-.191.166-.284.259L1.85,19.114l-.011.011a3.188,3.188,0,1,0,4.508,4.508l.015-.016L16.28,12.932a3.746,3.746,0,0,0,.253-.283,6.534,6.534,0,0,0,8.828-6.675ZM4.749,22.3a1.176,1.176,0,1,1,0-1.663A1.176,1.176,0,0,1,4.749,22.3Z"
                transform="translate(0 0)"

              />
            </g>
          </svg>
          <p>Revisão</p>
        </a>
        <a class="content-menu-footer-itens-elements-links" @click="tabMenuActive = 'help'" :class="{'active-tab': tabMenuActive && tabMenuActive == 'help'}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.144"
            height="26.144"
            viewBox="0 0 26.144 26.144"
          >
            <path
              d="M17.351,10.911c1.209,0,1.936.645,1.936,1.733,0,1.976-3.528,2.521-3.528,4.92a1.282,1.282,0,0,0,1.289,1.35c1.331,0,1.17-.988,1.654-1.694.644-.947,3.649-1.955,3.649-4.576,0-2.842-2.539-4.032-5.12-4.032-2.458,0-4.718,1.754-4.718,3.73a1.206,1.206,0,0,0,1.311,1.23C15.779,13.573,14.772,10.911,17.351,10.911Z"
              transform="translate(-4.36 -3.001)"
            />
            <path
              d="M20.367,28.089a1.653,1.653,0,1,0-1.653,1.653A1.66,1.66,0,0,0,20.367,28.089Z"
              transform="translate(-5.944 -9.211)"
            />
            <path
              d="M26.144,13.072A13.072,13.072,0,1,0,13.072,26.144,13.087,13.087,0,0,0,26.144,13.072Zm-24.841,0A11.769,11.769,0,1,1,13.072,24.841,11.783,11.783,0,0,1,1.3,13.072Z"
            />
          </svg>
          <p>Dúvidas</p>
        </a>
      </div>
      <!-- <div class="content-menu-footer-itens-div">
        <FormCotation :tabMenuActive="tabMenuActive" />
      </div> -->
    </div>
  </aside>
</template>

<script>
// import FormCotation from "@/components/Forms/Form.vue";
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      tabMenuActive: null,
    };
  },
  components: {
    // FormCotation,
  },
  computed: {
    ...mapGetters('Site', ['activeMenuFooter',])
  },
  methods: {
    ...mapMutations('Site', ['setActiveMenuFooter',])
  },
};
</script>

<style lang="scss">
.content-menu-footer {
  position: fixed;
  background: var(--white);
  bottom: 0;
  width: 100%;
  z-index: 6;
  height: 83px;
  transition: height ease-in-out 500ms;
  &-active {
    height: calc(100% - 25px);
    overflow-y: auto;
    padding-top: 25px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    .content-menu-footer-itens-elements {
      box-shadow: none;
    }
  }
  &-itens {
    &-elements {
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--white);
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
      &-links {
        padding: 15px 0px;
        text-decoration: none !important;
        svg {
          transition: 500ms;
          fill: #ccc;
        }
        p {
          font-size: 12px;
          font-family: "Font Regular";
          color: #cccccc;
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
      .btn-expand-form {
        height: 73px;
        width: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--main-color);
        border: 5px solid var(--white);
        border-radius: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin-top: -50px;
        padding: 0;
        transition: 500ms;
      }
      .active-tab {
        svg {
          fill: var(--main-color);
        }
      }
    }
  }
}
</style>