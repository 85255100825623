import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    menuOpen: false,
    activeMenuFooter: false,
    activeFormFloating: false,
    isDesktop: null,
    loading: false,
    loadingBanner: false,
    loadingBlog: false,
    blogs: [],
    blogDetail: {},
    layout: {},
    banners: [],
  },
  actions,
  getters,
  mutations,
};