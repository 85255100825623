export default {
	loading: state => state.loading,
	banners: state => state.banners,
	loadingBanner: state => state.loadingBanner,
	loadingBlog: state => state.loadingBlog,
	blogs: state => state.blogs,
	blogDetail: state => state.blogDetail,
	layout(state){
		if(sessionStorage.getItem('layout_configs')){
			return JSON.parse(sessionStorage.getItem('layout_configs'));
		}

		return state.layout;
	},
	menuOpen(state) {
	return state.menuOpen;
	},
	activeMenuFooter(state) {
	return state.activeMenuFooter;
	},
	activeFormFloating(state) {
	return state.activeFormFloating;
	},
	isDesktop(state) {
	return state.isDesktop;
	}		
};