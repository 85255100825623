export default function money (value) {	
    value = parseFloat(value);
    value = isNaN(value) ? 0 : value;

    if (typeof value !== "number") {
        return value;
    }

    let currentLocale = document.querySelector("html");

    var formatter = new Intl.NumberFormat(currentLocale.getAttribute("lang"), {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    return formatter.format(value);
}