import axios from 'axios';

export default {	
	async getLayoutConfigs(){
		return axios.get('/api/v1/site/configurations');
	},
	async getContent(params){
		return axios.post('/api/v1/site/get-save-content', params);
	},	
	async getContentLang(params){
		return axios.post(`/api/v1/site/get-lang`, params);
	},
	async getBrandSite(){
		return axios.get(`/api/v1/site/get-brand`);
	},
	async getBanners(){
		return axios.get(`/api/v1/banners/get-list`);
	},
	async getBlogs(params){
		return axios.get(`/api/v1/site/get-blogs`, {params: params});
	},
}