import Vue from 'vue'
import Vuex from 'vuex'

import Site from './Site';
import VehicleNew from './VehicleNew';
import VehicleUsed from './VehicleUsed';

Vue.use(Vuex);

export default new Vuex.Store({ 
  modules: {
    Site,
    VehicleNew,
    VehicleUsed,
  }
})
