<template>
    <swiper class="swiper-telphones" :options="swiperTelphone" v-if="layout.has_units_showing">
        <swiper-slide v-for="unit in layout.units_data" :key="unit.id">
        <a class="slider-telphone">
            <p class="slider-telphone-name">{{ unit.name }}</p>
            <!-- <p class="slider-telphone-telphones">(31) 3333-3333</p> -->
            <p class="slider-telphone-telphones">{{ unit.phone }}</p>
        </a>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <div class="swiper-button-prev swiper-button-prev-telphone" slot="button-prev">
        <svg
            style="transform: rotate(180deg)"
            xmlns="http://www.w3.org/2000/svg"
            width="17.972"
            height="30.591"
            viewBox="0 0 17.972 30.591"
        >
            <g transform="translate(-24.75)">
            <g transform="translate(24.751)">
                <path
                d="M42.234,14.1,28.621.489a1.677,1.677,0,0,0-2.367,0l-1,1a1.676,1.676,0,0,0,0,2.367L36.683,15.289,25.239,26.733a1.677,1.677,0,0,0,0,2.367l1,1a1.677,1.677,0,0,0,2.367,0L42.234,16.477a1.689,1.689,0,0,0,0-2.375Z"
                transform="translate(-24.751)"
                fill="#c00"
                />
            </g>
            </g>
        </svg>
        </div>
        <div class="swiper-button-next swiper-button-next-telphone" slot="button-next">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.972"
            height="30.591"
            viewBox="0 0 17.972 30.591"
        >
            <g transform="translate(-24.75)">
            <g transform="translate(24.751)">
                <path
                d="M42.234,14.1,28.621.489a1.677,1.677,0,0,0-2.367,0l-1,1a1.676,1.676,0,0,0,0,2.367L36.683,15.289,25.239,26.733a1.677,1.677,0,0,0,0,2.367l1,1a1.677,1.677,0,0,0,2.367,0L42.234,16.477a1.689,1.689,0,0,0,0-2.375Z"
                transform="translate(-24.751)"
                fill="#c00"
                />
            </g>
            </g>
        </svg>
        </div>
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import {mapGetters} from 'vuex';

export default {
    components: {
        Swiper,
        SwiperSlide,        
    },
    directives: {
        swiper: directive,
    },
    computed:{
        ...mapGetters('Site', ['layout']),
    },
    mounted(){
        if(this.layout.units_data && this.layout.units_data.length > 1){
            this.swiperTelphone.loop = true;
            this.swiperTelphone.breakpoints = {
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1400: {
                        slidesPerView: (this.layout.units_data.length > 2 ? 3 : 2),
                        spaceBetween: 50,
                    },
            };
        }else{
            this.swiperTelphone.breakpoints = {
                    1200: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    1400: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
            };
        }
    },
    data(){
        return{
            swiperTelphone: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 20,
                speed: 800,                
                watchOverflow: true,
                clickable: true,                
                autoplay: {
                delay: 5000,
                },
                pagination: {
                el: ".swiper-pagination-telphone",
                type: "bullets",
                clickable: true,
                },
                navigation: {
                nextEl: ".swiper-button-next-telphone",
                prevEl: ".swiper-button-prev-telphone",
                },
            },
        }
    }
}
</script>