<template>
  <form class="form-cotation" :name="'form-'+tabMenuActive">
    <input type="hidden" name="captation" :value="captations[tabMenuActive]">    
		<input type="hidden" name="departamento" :value="$t(departments[tabMenuActive])|stripHtml">
    <div class="form-cotation-itens">
      <div class="row">
        <div class="col-12">
          <div class="content-input">
            <input
              type="text"
              name="name"
              value=""
              :placeholder="$t('form.field_name')|stripHtml"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="content-input">
            <input
              type="text"
              name="email"
              :placeholder="$t('form.field_email')|stripHtml"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="content-input">
            <input type="text" name="document" :placeholder="$t('form.field_document')|stripHtml" required />
          </div>
        </div>
        <div class="col-12">
          <div class="content-input">
            <input
              type="text"
              name="mobile"
              :placeholder="$t('form.field_phone')|stripHtml"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <!-- <div class="content-input-select">
            <select name="state" required>
              <option value="">{{$t('form.field_state')|stripHtml}}</option>              
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.351"
              height="7.638"
              viewBox="0 0 11.351 7.638"
            >
              <g transform="translate(0 0)">
                <path
                  d="M11.169,101.336l-.372-.426a.57.57,0,0,0-.878,0l-4.241,4.832L1.432,100.9a.58.58,0,0,0-.439-.206.581.581,0,0,0-.439.206l-.372.424a.779.779,0,0,0,0,1l5.056,5.78a.6.6,0,0,0,.44.227h0a.6.6,0,0,0,.439-.227l5.051-5.764a.769.769,0,0,0,.181-.505A.761.761,0,0,0,11.169,101.336Z"
                  transform="translate(0 -100.698)"
                />
              </g>
            </svg>
          </div> -->
          <div class="content-input">
            <input
              type="text"
              name="state"
              :placeholder="$t('form.field_state')|stripHtml"
              required
            />
          </div>
        </div>
        <div class="col-6">
          <div class="content-input">
            <input type="text" name="city" :placeholder="$t('form.field_city')|stripHtml" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="layout.units_data.length > 1">
          <div class="content-input-select">
            <select name="unit_id" required>
              <option value="">{{$t('form.field_unit')|stripHtml}}</option>              
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.351"
              height="7.638"
              viewBox="0 0 11.351 7.638"
            >
              <g transform="translate(0 0)">
                <path
                  d="M11.169,101.336l-.372-.426a.57.57,0,0,0-.878,0l-4.241,4.832L1.432,100.9a.58.58,0,0,0-.439-.206.581.581,0,0,0-.439.206l-.372.424a.779.779,0,0,0,0,1l5.056,5.78a.6.6,0,0,0,.44.227h0a.6.6,0,0,0,.439-.227l5.051-5.764a.769.769,0,0,0,.181-.505A.761.761,0,0,0,11.169,101.336Z"
                  transform="translate(0 -100.698)"
                />
              </g>
            </svg>
          </div>
        </div>
        <input v-else type="hidden" name="unit" :value="layout.units_data[0].id">
        <div class="col-12">
          <div class="content-input">
            <input
              type="text"
              name="product"
              value=""
              :placeholder="$t('form.field_product')|stripHtml"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="content-input">
            <textarea name="message" :placeholder="$t('form.field_message')|stripHtml"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
						<div class="start-field">
							<input type="checkbox" name="accepted_terms" id="acept-polity" value="1" required />
							<label for="acept-polity">
                <router-link :to="{name: 'policyPrivacy'}"
                target="_blank">{{ $t('form.accepted_terms')|stripHtml }}</router-link>
              </label>
						</div>
					</div>
      </div>
      
      <div class="row">
        <div class="col-12">
          <button class="btn-send-cotation">{{ $t('form.button_submit')|stripHtml }}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    tabMenuActive: String
  },
  computed:{
    ...mapGetters('Site', ['layout'])
  },
  data(){
    return {
      captations: {
        'new': 'form-site-news',
        'new-ones': 'form-site-useds',
        'used': 'form-site-useds',
        'revision' : 'form-site-revision',
        'help': 'form-site-contato'
      },
      departments: {
        'new': 'form.department_vehicle_new',
        'used': 'form.department_vehicle_used',
        'new-ones': 'form.department_vehicle_used',
        'revision' : 'form.department_schedule',
        'help': 'form.department_consortium'
      },
    }
  }
};
</script>

<style lang="scss">
.content-form-cotation {
  position: absolute;
  top: 0px;
  max-width: 330px;
  z-index: 2;
  background: #FBFBFB;
  border-radius: 15px;
  box-shadow: 0px 3px 30px rgba(0,0,0,0.16);
  &-header {
    height: 50px;
    width: 100%;
    background: var(--main-color);
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 16px;
      font-family: 'Font Bold';
      text-transform: uppercase;
      color: var(--white);
      margin-bottom: 0;
    }
  }
  &-menu {
    display: flex;
    align-items: center;
    padding: 15px;
    .content-links {
      padding: 10px 0px;
      text-decoration: none !important;
      width: 25%;
      border-right: 1px solid #F3F3F3;
      border-bottom: 3px solid transparent;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        opacity: 0.6;
      }
      &:last-child {
        border-right: 0;
      }
      svg {
        transition: 500ms;
        fill: #ccc;
      }
      p {
        font-size: 12px;
        font-family: "Font Regular";
        color: #cccccc;
        margin-bottom: 0;
        margin-top: 10px;
        transition: 500ms;
      }
    }
    .active-tab {
      background: #F5F5F5;
      border-bottom: 3px solid var(--main-color);
      border-right: 1px solid transparent;
      svg {
        fill: var(--main-color);
      }
      p {
        color: var(--main-color);
        font-family: 'Font Bold';
      }
    }
  }
}
.form-cotation {
  padding: 0px 15px 15px 15px;
  background: var(--white);
  @media (min-width: 992px){
    background: #FBFBFB;
    border-radius: 0px 0px 5px 5px;
  }
  &-itens {
    .content-input {
      margin-bottom: 10px;
      input {
        height: 35px;
        width: 100%;
        border: 1px solid #cecece;
        border-radius: 5px;
        background: var(--white);
        padding-left: 12px;
        outline: none;
        font-size: 14px;
        font-family: "Font Regular";
        color: #5e5e5e;
        text-transform: uppercase;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #5e5e5e;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #5e5e5e;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #5e5e5e;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #5e5e5e;
        }
        &:not(:placeholder-shown){
          border: 0;
          box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        }
      }
      textarea {
        display: block;
        min-height: 60px;
        width: 100%;
        border: 1px solid #cecece;
        border-radius: 5px;
        background: var(--white);
        padding-left: 15px;
        padding-top: 15px;
        outline: none;
        font-size: 14px;
        font-family: "Font Regular";
        color: #5e5e5e;
        text-transform: uppercase;
        resize: none;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #5e5e5e;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #5e5e5e;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #5e5e5e;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #5e5e5e;
        }
        &:not(:placeholder-shown){
          border: 0;
          box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        }
      }
      &-select {
        margin-bottom: 10px;
        select {
          height: 30px;
          width: 100%;
          border: 1px solid #cecece;
          border-radius: 5px;
          background: var(--white);
          padding: 0px 30px 0px 12px;
          outline: none;
          font-size: 14px;
          font-family: "Font Regular";
          color: #5e5e5e;
          text-transform: uppercase;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #5e5e5e;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #5e5e5e;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #5e5e5e;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #5e5e5e;
          }
        }
        svg {
          position: absolute;
          right: 30px;
          top: 45%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
    }
    .start-field {
			text-align: left;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			label {
				font-size: 14px;
				font-family: 'Font Bold';
				color: #313134;
				margin-bottom: 0;
				a {
					color: var(--main-color) !important;
					text-decoration: underline !important;
					text-decoration-color: var(--main-color) !important;
					transition: 500ms;
					&:hover,&:focus {
						cursor: pointer;
						opacity: 0.6;
					}
				}
			}
			> input[type="checkbox"]:checked:before {
				content: "";
				height: 100%;
				width: 100%;
				background: url("../../assets/img/check.png");
				background-position: center !important;
				background-repeat: no-repeat !important;
			}
			> input[type="checkbox"]:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0px;
				border: 0;
			}
			> input[type="checkbox"] {
				border: 1px solid #CECECE;
				border-radius: 1;
				position: relative;
				height: 20px;
				width: 20px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				outline: 0 !important;
				margin: 0;
				margin-right: 10px;
			}
		}
    .btn-send-cotation {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color);
      border: 1px solid var(--main-color);
      border-radius: 10px;
      font-size: 18px;
      font-family: 'Font Black';
      color: var(--white);
      text-transform: uppercase;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        background: var(--white);
        color: var(--main-color);
      }
    }
  }
}
</style>