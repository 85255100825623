import VehicleNew from '@/api/VehicleNew';

export default {   
  async loadVehicleNews(context){  
    context.commit('setLoading', true);  
    await VehicleNew.getVehiclesNew().then((json) => {
      let response = json.data;
      context.commit('setLoading', false);
      context.commit('setCategories', response.data.categories);
      context.commit('setVehicles', response.data.vehicles);
    });
  },
  async loadVehicleDetail(context, params){  
    context.commit('setLoading', true);  
    await VehicleNew.getDetailVehicle(params).then((json) => {
      let response = json.data;
      context.commit('setLoading', false);
      context.commit('setDetails', response.data.vehicle);      
      context.commit('setOtherVechiles', response.data.other_vehicles);
    });
  }
};