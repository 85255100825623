<template>
  <footer v-if="Object.keys(layout).length > 0 && layout.menu_footer.length > 0" class="content-footer">
    <div class="content-footer-itens">
      <div class="content-footer-itens-links">
        <div class="content-footer-itens-links-div container">
          <div class="row">
            <template v-for="(group, index) in layout.menu_footer">            
              <ul :key="index" :class="'col-6 col-lg-'+getTotalCol">
                <li>
                  <p>{{ group.group }}</p>
                </li>
                <li v-for="(menu, menuIndex) in group.menus" :key="menuIndex">
                  <router-link v-if="menu.page != 'external'" :key="menu.name" class="btn-links" :to="{name: menu.page}">
                      {{ menu.name }}
                  </router-link>
                  <a v-else :key="menu.name" class="btn-links" :href="menu.url">
                      {{ menu.name }}
                  </a>
                </li>                
              </ul>
            </template>            
          </div>
        </div>
      </div>
      <div class="content-footer-itens-copyright">
        <div class="container content-footer-itens-copyright-div">
          <p class="text" v-if="Object.keys(layout).length > 0"> 
              Copyright {{ layout.company.name }} - 
              <span>{{ $t("footer.text_copyright")|stripHtml }}</span>
          </p>
          <!-- <p class="text"> Desenvolvido por 4tech.mobi </p> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('Site', ['layout']),
    getTotalCol(){
      let total = parseInt(this.layout.menu_footer.length);  
      let colSpan = total > 0 ? parseInt(12 / total) : 3;
  
      return colSpan;
    }
  },
}
</script>

<style lang="scss">
.content-footer {
  position: relative;
  @media (min-width: 992px){
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 128px;
      height: 4px;
      background: var(--main-color);
      border-radius: 10px;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    }
  }
  @media (max-width: 991px){
    background: #EDEDE3;
    padding-bottom: 130px;
  }
  &-itens {
    &-links {
      background: #EDEDE3;
      padding: 100px 0px;
      @media (max-width: 991px){
        padding: 30px 0px 15px 0px;
      }
      &-div {
        .row {
          margin: 0;
        }
        ul {
          padding: 0;
          list-style: none;
          text-align: left;
          @media (max-width: 991px){
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          li {
            p {
              font-size: 26px;
              font-family: 'Font Black';
              color: #3E3E3E;
              text-transform: uppercase;
              margin-bottom: 25px;
              @media (max-width: 991px){
                font-size: 18px;
                margin-bottom: 10px;
              }
              @media (max-width: 359px){
                font-size: 16px;
              }
            }
            a {
              font-size: 16px;
              font-family: 'Font Regular';
              color: #3E3E3E !important;
              display: block;
              line-height: 1;
              margin-bottom: 15px;
              transition: 500ms;
              @media (max-width: 991px){
                font-size: 13px;
              }
              @media (max-width: 359px){
                font-size: 12px;
              }
              &:hover,&:focus {
                cursor: pointer;
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
    &-copyright {
      height: 66px;
      background: var(--main-color);
      @media (max-width: 991px){
        height: initial;
        padding: 15px 0px;
        background: #EDEDE3;
      }
      &-div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px){
          flex-direction: column;
        }
        .text {
          font-size: 16px;
          font-family: 'Font Medium';
          color: var(--white);
          margin-bottom: 0;
          @media (max-width: 991px){
            font-size: 13px;
            color: #3E3E3E;
            span {
              display: block;
            }
            &:first-child {
              margin-bottom: 20px;
              font-family: 'Font Regular';
            }
          }
        }
      }
    }
  }
}
</style>